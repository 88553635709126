import moment from "moment"
import "moment/locale/sv"
import React from "react"
import MenuBar from "../../components/MenuBar/menuBar"
import EventGroups from "../../components/EventGroups/eventGroups"
import Footer from "../../components/Footer/footer"
import BaseLayout from "../../components/Layout/layout"
import "./channel-template.scss"
import { graphql } from "gatsby"

const _ = require("lodash")

const ChannelTemplate = ({ data, location, pageContext }) => {
  const channel = data.channel
  const backImageStyle = channel.pageBackground
    ? { backgroundImage: `url(${channel.pageBackground.file.url})` }
    : {}
  const channelLogo = channel.pageLogo
    ? `${channel.pageLogo.file.url}?w=250&h=250`
    : `${channel.logo.file.url}?w=250&h=250`
  const sports = data.sports.edges
  const channels = data.channels.edges
  const selectedDayStr =
    location.pathname === channel.uri
      ? ""
      : _.replace(location.pathname, `${channel.uri}/`, "")
  const selectedDay = moment(selectedDayStr).isValid() ? selectedDayStr : ""
  const channelEventEdges = _.filter(data.events.edges, ({ node }) =>
    _.includes(
      _.map(node.channels, nodeChannel => nodeChannel.id),
      channel.id
    )
  )

  const availableDays = _.uniqWith(
    _.map(channelEventEdges, edge =>
      moment(edge.node.date).locale("sv").format("YYYYMMDD")
    ),
    _.isEqual
  )

  return (
    <BaseLayout>
      <div className="channel-details-container" style={backImageStyle}>
        <div className="channel-details">
          <a
            href={channel.externalUrl}
            className="channel-details-logo-container"
          >
            <img
              src={channelLogo}
              className="channel-details-logo"
              alt={channel.name}
            />
          </a>
        </div>
      </div>
      <MenuBar
        sports={sports}
        channels={channels}
        availableDays={availableDays}
        selectedChannel={channel}
      />
      <div className="event-groups-container">
        <EventGroups eventEdges={channelEventEdges} selectedDay={selectedDay} />
      </div>
      <Footer links={pageContext.allLinks} />
    </BaseLayout>
  )
}

export default ChannelTemplate

export const pageQuery = graphql`
  query channelQuery($id: String!) {
    channel: contentfulChannel(contentful_id: { eq: $id }) {
      name
      id
      uri
      externalUrl
      logo {
        file {
          url
        }
      }
      externalUrl
    }
    channels: allContentfulChannel(filter: { node_locale: { eq: "sv-SE" } }) {
      edges {
        node {
          id
          name
          uri
        }
      }
    }
    sports: allContentfulSport(filter: { node_locale: { eq: "sv-SE" } }) {
      edges {
        node {
          name
          uri
          code
        }
      }
    }
    events: allContentfulEvent(filter: { node_locale: { eq: "sv-SE" } }) {
      edges {
        node {
          id
          uri
          date
          location
          betradarId
          everysportId
          bookmakerUrl
#          bookmaker {
#            name
#            externalUrl
#            logo {
#              file {
#                url
#              }
#            }
#            mobileLogo {
#              file {
#                url
#              }
#            }
#          }
          league {
            name
            uri
          }
          sport {
            code
            backgroundColor
            id
            contentful_id
          }
          homeTeam {
            name
          }
          awayTeam {
            name
          }
          detail {
            detail
          }
          channelLinks {
            channel
            externalUrl
          }
          channels {
            id
            name
            externalUrl
            logo {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
